/**
 * @name: 销售管理-自营商品发货接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 销售管理-自营商品发货接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IDelivery, IDeliveryParams } from "./types";

export const deliveryPageApi = (params: IDeliveryParams) => get<IPageRes<IDelivery[]>>('/admin/order/deliver/query', params)

export const deliveryOrderDetailApi = (id: string) => get('/admin/order/deliver/getOrderDtl', {id})

export const companyQueryApi = (companyName: string) => get('/admin/express/company/code/getList', {companyName})

export const halfSendApi = (data: any) => postJ('/admin/order/deliver/unpackingAndShipping', data)

export const batchSendApi = (data: any) => postJ('/admin/order/deliver/allShipments', data)

export const deliveryDetailApi = (id: string) => get('/admin/order/deliver/getDtlList', {id})

export const sendDeliveryDetailApi = (orderDeliverId: string) => get('/admin/order/deliver/getShippingDetails', {orderDeliverId})
