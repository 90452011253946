
/**
 * @name: 销售管理-自营商品发货
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 销售管理-自营商品发货
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import Big from "big.js"
import {
  deliveryPageApi,
  deliveryOrderDetailApi,
  companyQueryApi,
  halfSendApi,
  batchSendApi,
  deliveryDetailApi,
  sendDeliveryDetailApi
} from '@/apis/sale/send'
import {
  orderDeliveryDetailApi
} from '@/apis/sale/order'
import type {
  IDelivery,
  IDeliveryParams
} from '@/apis/sale/send/types'
import config from "@/config"
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, getlogisticStatus } from "@/utils/common";

@Component({})
export default class saleSend extends Vue {
  config = config
  getlogisticStatus = getlogisticStatus;
  Big = Big
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IDelivery[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IDeliveryParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IDelivery> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    column: [
      {
        label: '',
        type: 'selection',
        width: 50,
        align: 'center'
      },
      {
        label: '订单ID',
        prop: 'id',
        search: true,
        width: 180,
        align: 'center'
      },
      {
        label: '会员',
        prop: 'member',
        hide: true,
        search: true,
        placeholder: '昵称/手机号'
      },
      {
        label: '昵称',
        prop: 'nickName',
        align: 'center'
      },
      {
        label: '手机号',
        prop: 'phone',
        align: 'center',
        slot: true
      },
      {
        label: '商品数量',
        prop: 'payQty',
        align: 'center',
        slot: true
      },
      {
        label: '备注',
        prop: 'orderRemarks',
        align: 'center'
      },
      {
        label: '收货地址',
        prop: 'detailAddress',
        align: 'center',
        slot: true
      },
      {
        label: '下单时间',
        prop: 'orderTime',
        align: 'center',
        type: 'daterange',
        search: true
      }
    ]
  }

  // 订单商品发货进度弹窗
  qtyDialog = false

  selectRows: IDelivery[] = []

  allSendDialog = false

  allSendArr: IDelivery[] = []

  // 1 全部发货 2 批量发货
  allSendType = 1

  allSendForm: any = {}

  /**
   * 快递公司列表
   */
  companyList: any = []

  allSendRules: any = {
    shipperCode: [
      {
        required: true,
        message: '快递公司不能为空',
        trigger: 'blur'
      }
    ],
    logisticCode: [
      {
        required: true,
        message: '快递单号不能为空',
        trigger: 'blur'
      },
      {
        validator: function (rule: any, value: string, callback: Function) {
          if (!/^[A-Za-z0-9]+$/.test(value)) {
            return callback(new Error('只支持数字或字母'))
          }
          callback()
        },
        trigger: 'blur'
      }
    ]
  }

  detailArr1: any = []

  detailArr2: any = []

  // 拆包裹发货
  retrySendProductDialog = false

  retrySendProductObj: any = {}

  retrySendProductForm: any = {}

  searchLoading = false

  deliveryDetailDialog = false

  // 物流信息
  logisticsInfo: any = {}

  selectDeliveryRow: any = {}


  sendDeliveryDialog = false

  sendDeliveryList: any = []

  get ids () {
    return this.selectRows.map(item => item.id)
  }

  async remoteMethod (query: string) {
    this.searchLoading = true
    await this.getCompanyList(query)
    this.searchLoading = false
  }

  async openQtyDialog (row: IDelivery) {
    try {
      const arr1 = await deliveryOrderDetailApi(row.id);
      this.detailArr1 = arr1

      const arr2 = await deliveryDetailApi(row.id)
      this.detailArr2 = arr2
    } catch (e) {

    }
    this.qtyDialog = true
  }

  batchAllSend () {
    return new Promise((resolve, reject) => {
        batchSendApi(this.allSendArr.map(item => {
          return {
            "orderId": item.id,
            "logisticCode": this.allSendForm.logisticCode,
            "logisticName": this.allSendForm.logisticName,
            "shipperCode": this.allSendForm.shipperCode,
          }
        })).then(e => {
          if (e) {
            resolve('')
          } else {
            reject()
          }
        }).catch(() => {
          reject()
        })
    })
  }

  equalsAddress (addressItem1: IDelivery, addressItem2: IDelivery) {
    if (
      (addressItem1.province === addressItem2.province) &&
      (addressItem1.city === addressItem2.city) &&
      (addressItem1.region === addressItem2.region) &&
      (addressItem1.street === addressItem2.street) &&
      (addressItem1.detailAddress === addressItem2.detailAddress) &&
      (addressItem1.receiptPhone === addressItem2.receiptPhone) &&
      (addressItem1.receiptName === addressItem2.receiptName)
    ) {
      return true
    }
    return false
  }

  openBatchSend () {
    if (!this.selectRows || !this.selectRows.length) {
      return this.$message.error('请选择数据')
    }
    if (!this.selectRows.every(item => this.equalsAddress(item, this.selectRows[0]))) {
      return this.$message.error('收货地址不同，不允许批量发货！')
    }
    this.allSendArr = deepCopy(this.selectRows)
    this.allSendType = 2
    this.allSendDialog = true
  }

  allSendEnter (done: Function, loading: Function) {
    // @ts-ignore
    this.$refs.allSendRef.validate(valid => {
      if (valid) {
        this.batchAllSend().then(() => {
          this.$message.success('操作成功!')
          this.getList()
          done()
        }).finally(() => {
          loading()
        })
      } else {
        loading()
      }
    })
  }

  retrySendProductEnter (done: Function, loading: Function) {
    let arr: any = deepCopy(this.retrySendProductObj.orderProductVoList) || []
    for (let i = 0; i < arr.length; i++) {
      const item: any = arr[i]
      if (item.retryQty !== '') {
        if (!/^[1-9]\d*$/.test(item.retryQty)) {
          loading()
          return this.$message.error("请输入正整数的发货数量")
        }
        if (parseInt(item.retryQty) > parseInt(item.noDeliveryQty)) {
          loading()
          return this.$message.error("发货数量不能超出待发货数量")
        }
      }
    }
    // 0和''都不传
    const filterArr: any = arr.filter((item: any) => (item.retryQty !== '' && parseInt(item.retryQty) !== 0))

    if (!filterArr || !filterArr.length) {
      loading()
      return this.$message.error("商品发货数量为空，请输入发货数量!")
    }
    // @ts-ignore
    this.$refs.retrySendProductRef.validate(valid => {
      if (valid) {
        console.log(this.retrySendProductObj)
        halfSendApi({
          "orderId": this.retrySendProductObj.orderId,
          "logisticCode": this.retrySendProductForm.logisticCode,
          "shipperCode": this.retrySendProductForm.shipperCode,
          "logisticName": this.retrySendProductForm.logisticName,
          "dtos": filterArr.map((item: any) => {
            return {
              "orderDtlId": item.id,
              "deliveryQty": parseInt(item.retryQty),
              "productId": item.productId,
              "skuCode": item.skuCode,
              "skuName": item.skuName,
              "productName": item.productName
            }
          })
        }).then(e => {
          if (e) {
            this.getList()
            this.$message.success('操作成功!')
            done()
          }
        }).finally(() => {
          loading()
        })
      } else {
        loading()
      }
    })
  }

  retrySendProductClose () {
    this.$set(this, 'retrySendProductObj', {})
    this.$set(this, 'retrySendProductForm', {})
    this.getCompanyList()
  }

  openRetrySendProductDialog (row: IDelivery) {
    deliveryOrderDetailApi(row.id).then(e => {
      this.$set(this, 'retrySendProductObj', {
        orderProductVoList: (e||[]).map((item: any) => {
          const noDeliveryQty: number = new Big(item.payQty || 0).minus(item.deliveryQty || 0).toNumber()
          return {
            ...item,
            // 待发货数量
            noDeliveryQty: noDeliveryQty,
            // 状态
            deliveryStatus: noDeliveryQty === 0 ? 2 : 1,
            // 本次发货数量
            retryQty: ''
          }
        }),
        orderId: row.id,
        receiptName: row.receiptName,
        receiptPhone: row.receiptPhone,
        province: row.province,
        city: row.city,
        region: row.region,
        street: row.street,
        detailAddress: row.detailAddress
      })
      this.retrySendProductDialog = true
    })
  }

  allSendClose () {
    this.allSendForm = {}
    this.allSendArr = []
    this.allSendType = 1
    this.getCompanyList()
  }

  openAllSend (row: IDelivery) {
    this.allSendArr = [deepCopy(row)]
    this.allSendType = 1
    this.allSendDialog = true
  }

  shipperCodeChange (val: string) {
    const item = this.companyList.find((item: any) => item.companyCode === val)
    this.$set(this.retrySendProductForm, 'logisticName', item.companyName)
  }

  allSendFormCodeChange (val: string) {
    const item = this.companyList.find((item: any) => item.companyCode === val)
    this.$set(this.allSendForm, 'logisticName', item.companyName)
  }

  getSpDataValues (str: string) {
    let json: {key: string, value: string}[];

    try {
      json = JSON.parse(str || '[]')
    } catch (e) {
      json = []
    }

    return json.map(item => item.value).join(';')
  }

  getCompanyList (keyword?: string) {
    return new Promise(resolve => {
      companyQueryApi(keyword || '').then(e => {
        this.companyList = e.slice(0, 100);
        resolve(null)
      })
    })
  }

  openDeliveryDetailDialog (row: any) {
    this.selectDeliveryRow = row
    this.logisticsInfo.logisticName = row.logisticName
    this.logisticsInfo.logisticCode = row.logisticCode
    orderDeliveryDetailApi(row.id).then(e => {
      this.logisticsInfo.list = e || []
      this.deliveryDetailDialog = true
    })
  }

  openSendDeliveryDialog (row: any) {
    sendDeliveryDetailApi(row.id).then(e => {
      this.sendDeliveryList = e;
      this.sendDeliveryDialog = true
    })
  }

  getList () {
    this.tableLoading = true

    const query: IDeliveryParams = deepCopy(this.queryParam)
    if (query.orderTime && query.orderTime.length) {
      query.orderStartTime = query.orderTime[0]
      query.orderEndTime = query.orderTime[1]
    } else {
      query.orderStartTime = ''
      query.orderEndTime = ''
    }
    delete query.orderTime

    deliveryPageApi(query).then(e => {
      this.tableData = e.list
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  created () {
    this.getCompanyList()
    this.getList()
  }
}
